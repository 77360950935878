var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoggedIn
    ? _c("div", { staticClass: "add-to-cart-component" }, [
        _vm.showDetails
          ? _c("div", { staticClass: "ais-hits--content" }, [
              _c("p", { attrs: { itemprop: "name headline" } }, [
                _c("a", {
                  staticClass: "ais-hits--title-link",
                  attrs: {
                    href: _vm.item.permalink.replace(
                      "brumby.test",
                      "brumby-staging.dev.fweb.com.au"
                    ),
                    title: _vm.item.post_title,
                    itemprop: "url",
                  },
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.item._highlightResult.post_title.value
                    ),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "secondary" }, [
                _vm._v("\n      " + _vm._s(_vm.item.author) + "\n    "),
              ]),
              _vm._v(" "),
              _vm.item.price
                ? _c("div", { staticClass: "price-wrapper" }, [
                    _c("p", { staticClass: "price" }, [
                      _vm._v(
                        "\n        $" + _vm._s(_vm.price(_vm.item)) + "\n      "
                      ),
                    ]),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "quantity-selector" }, [
          _c(
            "label",
            {
              staticClass: "sr-only",
              attrs: { for: "product-quantity-selector-" + _vm.postId },
            },
            [_vm._v("\n      Quantity\n    ")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.quantity,
                expression: "quantity",
              },
            ],
            staticClass: "product-quantity-selector",
            attrs: {
              type: "number",
              id: "product-quantity-selector-" + _vm.postId,
            },
            domProps: { value: _vm.quantity },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.quantity = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("span", [
          _c(
            "a",
            {
              staticClass: "btn btn-brumby-3 add-to-cart",
              attrs: { disabled: _vm.sending },
              on: { click: _vm.addToCart },
            },
            [
              _vm._v("\n    Add to cart\n    "),
              !_vm.sending && !_vm.sent
                ? _c("i", { staticClass: "fal fa-plus-circle" })
                : _vm._e(),
              _vm._v(" "),
              _vm.sending && !_vm.sent
                ? _c("i", { staticClass: "fas fa-spinner-third fa-spin" })
                : _vm._e(),
              _vm._v(" "),
              !_vm.sending && _vm.sent
                ? _c("i", { staticClass: "fal fa-check-circle" })
                : _vm._e(),
            ]
          ),
        ]),
      ])
    : _c("div", { staticClass: "add-to-cart-component ms-auto" }, [
        _c("a", { attrs: { href: "#login" }, on: { click: _vm.openLogin } }, [
          _vm._v("\n    Login To See Prices\n  "),
        ]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }