var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex flex-column" }, [
    _c("h3", { staticClass: "underline" }, [
      _vm._v("Order #" + _vm._s(this.invoice)),
    ]),
    _vm._v(" "),
    !_vm.invoiceHistory
      ? _c("div", { staticClass: "fa-3x d-flex mt-5" }, [
          _c("i", { staticClass: "fas fa-circle-notch fa-spin mx-auto " }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.invoiceHistory.line_items
      ? _c("div", { staticClass: "mt-5" }, [
          _c("p", [
            _vm._v(
              "Invoice Number #" +
                _vm._s(this.invoice) +
                " as placed on " +
                _vm._s(_vm.invoiceHistory.invoice_date)
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.invoiceHistory.line_items
      ? _c("h4", [_vm._v("Items in order")])
      : _vm._e(),
    _vm._v(" "),
    _vm.invoiceHistory.line_items
      ? _c("div", { staticClass: "table-responsive" }, [
          _c("table", { staticClass: "table" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "tbody",
              [
                _vm._l(_vm.invoiceHistory.line_items, function (line_item) {
                  return _c("tr", [
                    _c("td", [_vm._v(_vm._s(line_item.isbn))]),
                    _vm._v(" "),
                    _c("td", [
                      _c(
                        "a",
                        { attrs: { target: "_blank", href: line_item.url } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(line_item.title) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(line_item.qty_supplied))]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$" + _vm._s(_vm.lineTotal(line_item)))]),
                  ])
                }),
                _vm._v(" "),
                _c("tr", [
                  _c("th", { attrs: { colspan: "2" } }),
                  _vm._v(" "),
                  _c("th", [_vm._v("Total GST")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v("$" + _vm._s(_vm.invoiceHistory.invoice_total_gst)),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("th", { attrs: { colspan: "2" } }),
                  _vm._v(" "),
                  _c("th", [_vm._v("Order Total")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "$" + _vm._s(_vm.invoiceHistory.invoice_total_incgst)
                    ),
                  ]),
                ]),
              ],
              2
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("th", [_vm._v("ISBN")]),
      _vm._v(" "),
      _c("th", [_vm._v("Product")]),
      _vm._v(" "),
      _c("th", [_vm._v("Qty Supplied")]),
      _vm._v(" "),
      _c("th", [_vm._v("Line Total")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }