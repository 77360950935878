"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * This class handles ajax add to cart for the algolia populated search frontend
 */
class AddToCart {
    constructor() {
        console.log('wat');
        document.addEventListener('click', (e) => {
            const target = e.target;
            if (!target) {
                console.log('nah');
                return;
            }
            if (target.classList.contains('quantity-selector-minus') && target.dataset.productId) {
                this.addToCartButtonHandler(target);
            }
            if (target.classList.contains('quantity-selector-minus') && target.dataset.input) {
                const input = document.getElementById(target.dataset.input);
                this.quantityPlusHandler(input);
            }
            if (target.classList.contains('quantity-selector-plus') && target.dataset.input) {
                const input = document.getElementById(target.dataset.input);
                this.quantityPlusHandler(input);
            }
        });
    }
    addToCartButtonHandler(target) {
        console.log(target);
        const productId = target.dataset.productId;
        const quantityInput = document.getElementById('product-quantity-selector-' + productId);
        const data = {
            action: 'woocommerce_ajax_add_to_cart',
            product_id: productId,
            quantity: quantityInput.value,
        };
        $(document.body).trigger('adding_to_cart', [target, data]);
        $.ajax({
            type: 'post',
            url: window.wc_add_to_cart_params.ajax_url,
            data: data,
            beforeSend: function () {
                target.classList.add('loading');
                target.classList.remove('added');
            },
            complete: function () {
                target.classList.add('added');
                target.classList.remove('loading');
            },
            success: function (response) {
                if (response.error && response.product_url) {
                    window.location = response.product_url;
                    return;
                }
                else {
                    $(document.body).trigger('added_to_cart', [response.fragments, response.cart_hash, target]);
                }
            },
        });
    }
    quantityMinusHandler(target) {
        const value = parseInt(target.value) - 1;
        target.value = value.toString();
    }
    quantityPlusHandler(target) {
        const value = parseInt(target.value) + 1;
        target.value = value.toString();
    }
}
exports.default = AddToCart;
