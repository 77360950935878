<template>
  <article class="ais-Hits-item-list" itemtype="http://schema.org/Article" v-if="item">
    <div class="ais-hits--thumbnail" v-if="item.images.thumbnail">
      <a :href="item.permalink.replace('brumby.test', 'brumby-staging.dev.fweb.com.au')"
         :title="item.post_title"
         class="ais-hits--thumbnail-link">
        <img :src="this.thumbnail"
             :alt="item.post_title"
             :title="item.post_title" itemprop="image" />
      </a>
    </div>

    <div class="ais-hits--content">
      <p itemprop="name headline">
        <a :href="item.permalink.replace('brumby.test', 'brumby-staging.dev.fweb.com.au')"
           :title="item.post_title" class="ais-hits--title-link" itemprop="url" v-html="item._highlightResult.post_title.value">
        </a>
      </p>
      <p class="secondary">
        {{ item.author }}
      </p>
      <div class="print-only">
        <span class="content">
          {{ item.content.substring(0, 150) }}...
        </span>
        <span class="barcode">
          <p>
            {{ item.sku }}
          </p>
        </span>
        <span v-if="isLoggedIn">
          RRP: ${{ item.price }}
        </span>
      </div>
    </div>
    <div class="price-wrapper" v-if="isLoggedIn">
      <p class="price">
        ${{ price(item) }}
      </p>
    </div>
    <add-to-cart :post-id="item.post_id"/>
  </article>

</template>

<script>
import AddToCart from "./AddToCart";
export default {
  components: {AddToCart},
  props: {
    item: {
      type: Object,
      required: false,
    },
  },
  methods: {
    price(item) {
      const discount = parseFloat(document.body.dataset.discount);
      return (item.price - (item.price * discount / 100)).toFixed(2);
    },
  },
  computed: {
    isLoggedIn() {
      return document.body.classList.contains( 'logged-in' );
    },
    thumbnail() {
      if(this.item && this.item.images.thumbnail) {
        return this.item.images.thumbnail.url.replace('-500x500', '');
      }

      return '';
    }
  }
}
</script>

<style scoped>

</style>
