"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const component_1 = require("../packages/component-framework/component");
const fragmentHandler_1 = require("../woocommerce/fragmentHandler");
class SaveAsDraftOrder extends component_1.Component {
    constructor(element) {
        super(element);
        this.registerEventHandlers();
    }
    registerEventHandlers() {
        this.element.querySelectorAll('.brumby-save-as-draft').forEach((addToCartButton) => {
            addToCartButton.addEventListener('click', (e) => {
                e.preventDefault();
                this.openSlide();
            });
        });
        this.element.querySelectorAll('.brumby-save-as-draft-cancel').forEach((cancelButton) => {
            cancelButton.addEventListener('click', (e) => {
                e.preventDefault();
                this.closeSlide();
            });
        });
        this.element.querySelectorAll('.save-as-draft-form').forEach((form) => {
            const saveOrderForm = form;
            console.log(saveOrderForm);
            const instance = this;
            form.addEventListener('submit', function (e) {
                e.preventDefault();
                const formData = new FormData(saveOrderForm);
                const name = formData.get('draft-name');
                instance.saveOrder(name);
            });
        });
    }
    openSlide() {
        this.element.querySelectorAll('.save-as-draft-slide').forEach((saveAsDraftSlide) => {
            saveAsDraftSlide.classList.add('active');
            saveAsDraftSlide.querySelectorAll('input[name="draft-name"]').forEach((draftNameInput) => {
                const el = draftNameInput;
                el.focus();
            });
        });
    }
    closeSlide() {
        this.element.querySelectorAll('.save-as-draft-slide').forEach((saveAsDraftSlide) => {
            saveAsDraftSlide.classList.remove('active');
        });
    }
    saveOrder(name) {
        jQuery.post(window.wc_add_to_cart_params.ajax_url, {
            action: 'save_draft_cart',
            name: name,
        }, (data, status) => {
            if (data.fragments) {
                (0, fragmentHandler_1.updateFragments)(data);
                this.closeSlide();
                /** @ts-ignore **/
                window.location = '/my-account/draft-orders/';
            }
        });
    }
}
exports.default = SaveAsDraftOrder;
SaveAsDraftOrder.selector = '.offcanvas-cart';
