var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h3", { staticClass: "underline" }, [_vm._v("Your Invoice History")]),
    _vm._v(" "),
    !_vm.invoiceHistory
      ? _c("div", { staticClass: "fa-3x d-flex mt-5" }, [
          _c("i", { staticClass: "fas fa-circle-notch fa-spin mx-auto " }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.invoiceHistory
      ? _c("div", { staticClass: "table-responsive mt-5" }, [
          _c("table", { staticClass: "table" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "tbody",
              [
                !_vm.invoiceHistory
                  ? _c("tr", [
                      _c("td", { attrs: { colspan: "5" } }, [
                        _vm._v("No invoices found"),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.invoiceHistory, function (invoice) {
                  return _c("tr", [
                    _c("td", [_vm._v("#" + _vm._s(invoice.omnis_Invoice))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(invoice.invoice_date))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(invoice.status))]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$" + _vm._s(invoice.invoice_amount))]),
                    _vm._v(" "),
                    _c("td", [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-sm btn-brumby-3",
                          attrs: {
                            href:
                              "/my-account/invoices/" + invoice.omnis_Invoice,
                          },
                        },
                        [_vm._v("\n              View Invoice\n            ")]
                      ),
                    ]),
                  ])
                }),
              ],
              2
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("th", [_vm._v("Invoice")]),
      _vm._v(" "),
      _c("th", [_vm._v("Date")]),
      _vm._v(" "),
      _c("th", [_vm._v("Order Status")]),
      _vm._v(" "),
      _c("th", [_vm._v("Invoice Amount")]),
      _vm._v(" "),
      _c("th", [_vm._v("Actions")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }