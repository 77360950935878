var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.state && _vm.state.results && _vm.state.results.nbHits === 0
    ? _c("div", [
        _c(
          "div",
          { staticClass: "hits-empty-state" },
          [
            _c(
              "svg",
              {
                staticClass: "hits-empty-state-image",
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  "xmlns:xlink": "http://www.w3.org/1999/xlink",
                  width: "138",
                  height: "138",
                },
              },
              [
                _c(
                  "defs",
                  [
                    _c(
                      "linearGradient",
                      {
                        attrs: {
                          id: "c",
                          x1: "50%",
                          x2: "50%",
                          y1: "100%",
                          y2: "0%",
                        },
                      },
                      [
                        _c("stop", {
                          attrs: { offset: "0%", "stop-color": "#F5F5FA" },
                        }),
                        _vm._v(" "),
                        _c("stop", {
                          attrs: { offset: "100%", "stop-color": "#FFF" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        id: "b",
                        d: "M68.71 114.25a45.54 45.54 0 1 1 0-91.08 45.54 45.54 0 0 1 0 91.08z",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "filter",
                      {
                        attrs: {
                          id: "a",
                          width: "140.6%",
                          height: "140.6%",
                          x: "-20.3%",
                          y: "-15.9%",
                          filterUnits: "objectBoundingBox",
                        },
                      },
                      [
                        _c("feOffset", {
                          attrs: {
                            dy: "4",
                            in: "SourceAlpha",
                            result: "shadowOffsetOuter1",
                          },
                        }),
                        _vm._v(" "),
                        _c("feGaussianBlur", {
                          attrs: {
                            in: "shadowOffsetOuter1",
                            result: "shadowBlurOuter1",
                            stdDeviation: "5.5",
                          },
                        }),
                        _vm._v(" "),
                        _c("feColorMatrix", {
                          attrs: {
                            in: "shadowBlurOuter1",
                            result: "shadowMatrixOuter1",
                            values:
                              "0 0 0 0 0.145098039 0 0 0 0 0.17254902 0 0 0 0 0.380392157 0 0 0 0.15 0",
                          },
                        }),
                        _vm._v(" "),
                        _c("feOffset", {
                          attrs: {
                            dy: "2",
                            in: "SourceAlpha",
                            result: "shadowOffsetOuter2",
                          },
                        }),
                        _vm._v(" "),
                        _c("feGaussianBlur", {
                          attrs: {
                            in: "shadowOffsetOuter2",
                            result: "shadowBlurOuter2",
                            stdDeviation: "1.5",
                          },
                        }),
                        _vm._v(" "),
                        _c("feColorMatrix", {
                          attrs: {
                            in: "shadowBlurOuter2",
                            result: "shadowMatrixOuter2",
                            values:
                              "0 0 0 0 0.364705882 0 0 0 0 0.392156863 0 0 0 0 0.580392157 0 0 0 0.2 0",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "feMerge",
                          [
                            _c("feMergeNode", {
                              attrs: { in: "shadowMatrixOuter1" },
                            }),
                            _vm._v(" "),
                            _c("feMergeNode", {
                              attrs: { in: "shadowMatrixOuter2" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
                  _c("circle", {
                    attrs: {
                      cx: "68.85",
                      cy: "68.85",
                      r: "68.85",
                      fill: "#5468FF",
                      opacity: ".07",
                    },
                  }),
                  _vm._v(" "),
                  _c("circle", {
                    attrs: {
                      cx: "68.85",
                      cy: "68.85",
                      r: "52.95",
                      fill: "#5468FF",
                      opacity: ".08",
                    },
                  }),
                  _vm._v(" "),
                  _c("use", {
                    attrs: {
                      fill: "#000",
                      filter: "url(#a)",
                      "xlink:href": "#b",
                    },
                  }),
                  _vm._v(" "),
                  _c("use", { attrs: { fill: "url(#c)", "xlink:href": "#b" } }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M76.01 75.44c5-5 5.03-13.06.07-18.01a12.73 12.73 0 0 0-18 .07c-5 4.99-5.03 13.05-.07 18a12.73 12.73 0 0 0 18-.06zm2.5 2.5a16.28 16.28 0 0 1-23.02.09A16.29 16.29 0 0 1 55.57 55a16.28 16.28 0 0 1 23.03-.1 16.28 16.28 0 0 1-.08 23.04zm1.08-1.08l-2.15 2.16 8.6 8.6 2.16-2.15-8.6-8.6z",
                      fill: "#5369FF",
                    },
                  }),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "hits-empty-state-title" }, [
              _vm._v(
                "\n      Sorry, we can't find any matches to your query!\n    "
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "hits-empty-state-description" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.state.results.getRefinements().length > 0
                      ? "Try to reset your applied filters."
                      : "Please try another query."
                  ) +
                  "\n    "
              ),
            ]),
            _vm._v(" "),
            _c("ais-clear-refinements", {
              scopedSlots: _vm._u(
                [
                  {
                    key: "resetLabel",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "clear-filters" }, [
                          _c(
                            "svg",
                            {
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                width: "11",
                                height: "11",
                                viewBox: "0 0 11 11",
                              },
                            },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    fill: "none",
                                    "fill-rule": "evenodd",
                                  },
                                },
                                [
                                  _c("path", { attrs: { d: "M0 0h11v11H0z" } }),
                                  _vm._v(" "),
                                  _c("path", {
                                    attrs: {
                                      fill: "#000",
                                      "fill-rule": "nonzero",
                                      d: "M8.26 2.75a3.896 3.896 0 1 0 1.102 3.262l.007-.056a.49.49 0 0 1 .485-.456c.253 0 .451.206.437.457 0 0 .012-.109-.006.061a4.813 4.813 0 1 1-1.348-3.887v-.987a.458.458 0 1 1 .917.002v2.062a.459.459 0 0 1-.459.459H7.334a.458.458 0 1 1-.002-.917h.928z",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v("\n          Clear filters\n        "),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                263131908
              ),
            }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }