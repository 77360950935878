"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const component_1 = require("../packages/component-framework/component");
const bootstrap_1 = require("bootstrap");
class LoginModal extends component_1.Component {
    constructor(element) {
        super(element);
        this.loginModal = null;
        this.registerEventListeners();
        if (window.location.hash == LoginModal.hash && this.loginModal) {
            this.loginModal.toggle();
        }
        window.openLoginModal = () => {
            if (!this.loginModal) {
                return;
            }
            this.loginModal.toggle();
        };
    }
    registerEventListeners() {
        console.log('ree');
        this.loginModal = new bootstrap_1.Modal(this.element, {});
        document.querySelectorAll('a').forEach((element) => {
            const anchor = element;
            if (anchor.hash !== LoginModal.hash) {
                return;
            }
            anchor.addEventListener('click', (e) => {
                if (!this.loginModal) {
                    return;
                }
                this.loginModal.toggle();
            });
        });
        this.element.addEventListener('hide.bs.modal', () => {
            this.removeHash();
        });
    }
    removeHash() {
        window.location.hash = '';
    }
}
exports.default = LoginModal;
LoginModal.selector = '#login-modal';
LoginModal.hash = '#login';
