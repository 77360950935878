import { history as historyRouter } from 'instantsearch.js/es/lib/routers';

const hitsPerPageItemsValue = [16, 32, 64];

function getFallbackHitsPerPageRoutingValue(hitsPerPageValue) {
  if (hitsPerPageItemsValue.indexOf(Number(hitsPerPageValue)) !== -1) {
    return hitsPerPageValue;
  }

  return undefined;
}

const sortByItemsValue = [
  'wp_posts_product',
  'wp_posts_product_price_asc',
  'wp_posts_product_price_desc',
  'wp_posts_product_release_date_timestamp_asc',
  'wp_posts_product_release_date_timestamp_desc',
];

function getFallbackSortByRoutingValue(sortByValue) {
  if (sortByItemsValue.indexOf(sortByValue) !== -1) {
    return sortByValue;
  }

  return undefined;
}

const routeStateDefaultValues = {
  s: '',
  page: '1',
  author: undefined,
  stock_status: undefined,
  taxonomies_pub: undefined,
  pa_format: undefined,
  category: '',
  price: '',
  release_date_timestamp: '',
  sortBy: 'instant_search',
  hitsPerPage: '20',
};


function categoryStateToRoute(levels) {
  if(!window.brumby_categories) {
    return '';
  }

  if(window.brumby_categories[levels.join(' > ')]) {
    return window.brumby_categories[levels.join(' > ')];
  }
  return '';
}

function categoryRouteToState()
{
  if(!window.brumby_categories) {
    return '';
  }

  let pathname = window.location.pathname;

  let state = [];

  Object.entries(window.brumby_categories).forEach((element, index) => {
    if(pathname === element[1]) {
      state = element[0].split(" > 0");
    }
  });

  return state;
}

const originalWindowTitle = document.title;

const router = historyRouter({
  windowTitle({ category, s }) {
    const queryTitle = s ? `Results for "${s}"` : '';

    return [queryTitle, category, originalWindowTitle]
      .filter(Boolean)
      .join(' | ');
  },

  createURL({ qsModule, routeState, location }) {
    const { protocol, hostname, port = '', pathname, hash } = location;
    const portWithPrefix = port === '' ? '' : `:${port}`;

    const baseUrl =
      `${protocol}//${hostname}${portWithPrefix}`;

    const categoryPath = routeState.category ? categoryStateToRoute(routeState.category) : ''

    const queryParameters = {};

    if (
      routeState.s &&
      routeState.s !== routeStateDefaultValues.s
    ) {
      queryParameters.s = encodeURIComponent(routeState.s);
    } else {
      queryParameters.s = '';
    }
    if (routeState.page && routeState.page !== routeStateDefaultValues.page) {
      queryParameters.page = routeState.page;
    }
    if (
      routeState.author &&
      routeState.author !== routeStateDefaultValues.author
    ) {
      queryParameters.author = routeState.author.map(encodeURIComponent);
    }

    if (
      routeState.stock_status &&
      routeState.stock_status !== routeStateDefaultValues.stock_status
    ) {
      queryParameters.stock_status = routeState.stock_status.map(encodeURIComponent);
    }

    if (
      routeState.taxonomies_pub &&
      routeState.taxonomies_pub !== routeStateDefaultValues.taxonomies_pub
    ) {
      queryParameters.taxonomies_pub = routeState.taxonomies_pub.map(encodeURIComponent);
    }

    if (
      routeState.pa_format &&
      routeState.pa_format !== routeStateDefaultValues.pa_format
    ) {
      queryParameters.pa_format = routeState.pa_format.map(encodeURIComponent);
    }

    if (
      routeState.price &&
      routeState.price !== routeStateDefaultValues.price
    ) {
      queryParameters.price = routeState.price;
    }

    if (
      routeState.release_date_timestamp &&
      routeState.release_date_timestamp !== routeStateDefaultValues.release_date_timestamp
    ) {
      queryParameters.release_date_timestamp = routeState.release_date_timestamp;
    }

    if (
      routeState.sortBy &&
      routeState.sortBy !== routeStateDefaultValues.sortBy
    ) {
      queryParameters.sortBy = routeState.sortBy;
    }
    if (
      routeState.hitsPerPage &&
      routeState.hitsPerPage !== routeStateDefaultValues.hitsPerPage
    ) {
      queryParameters.hitsPerPage = routeState.hitsPerPage;
    }

    const queryString = qsModule.stringify(queryParameters, {
      addQueryPrefix: true,
      arrayFormat: 'repeat',
    });



    let finalUrl = '';

    if(categoryPath) {
      finalUrl = `${baseUrl}${categoryPath}${queryString}${hash}`;
    } else {
      finalUrl = `${baseUrl}${queryString}${hash}`
    }

    return finalUrl;
  },


  parseURL({ qsModule, location }) {
    const pathnameMatches = location.pathname.match(/(.*?)\/?$/);
    const category = categoryRouteToState();

    const queryParameters = qsModule.parse(location.search.slice(1));
    const {
      s = '',
      page = 1,
      author = [],
      stock_status = [],
      taxonomies_pub = [],
      pa_format = [],
      price,
      release_date_timestamp,
    } = queryParameters;

    // `qs` does not return an array when there's a single value.
    const allAuthors = Array.isArray(author) ? author : [author].filter(Boolean);
    const allStockStatus = Array.isArray(stock_status) ? stock_status : [stock_status].filter(Boolean);
    const allPublishers = Array.isArray(taxonomies_pub) ? taxonomies_pub : [taxonomies_pub].filter(Boolean);
    const allFormats = Array.isArray(pa_format) ? pa_format : [pa_format].filter(Boolean);


    const hitsPerPage = getFallbackHitsPerPageRoutingValue(
      queryParameters.hitsPerPage
    );
    const sortBy = getFallbackSortByRoutingValue(queryParameters.sortBy);

    const response = {
      s: decodeURIComponent(s),
      page,
      author: allAuthors.map(decodeURIComponent),
      stock_status: allStockStatus.map(decodeURIComponent),
      taxonomies_pub: allPublishers.map(decodeURIComponent),
      pa_format: allFormats.map(decodeURIComponent),

      category,
      price,
      release_date_timestamp,
      sortBy,
      hitsPerPage,
    };

    return response;
  },
});



const getStateMapping = ({ indexName }) => ({
  stateToRoute(uiState) {

    const indexUiState = uiState[indexName];

    return {
      s: indexUiState.query,
      page: indexUiState.page,
      // refinementList
      author: indexUiState.refinementList && indexUiState.refinementList.author,
      stock_status: indexUiState.refinementList && indexUiState.refinementList.stock_status,
      taxonomies_pub: indexUiState.refinementList && indexUiState.refinementList['taxonomies.pub'],
      pa_format: indexUiState.refinementList && indexUiState.refinementList['taxonomies.pa_format'],

      category: indexUiState.hierarchicalMenu ? (indexUiState.hierarchicalMenu['taxonomies_hierarchical.product_cat.lvl0']) : '',
      price: indexUiState.range && indexUiState.range.price,
      release_date_timestamp: indexUiState.range && indexUiState.range.release_date_timestamp,
      sortBy: indexUiState.sortBy,
      hitsPerPage:
        (indexUiState.hitsPerPage && String(indexUiState.hitsPerPage)) ||
        undefined,
    };
  },

  routeToState(routeState) {
    let response = {
      [indexName]: {
        query: routeState.s,
        page: routeState.page,
        hierarchicalMenu: {
          'taxonomies_hierarchical.product_cat.lvl0':
            (routeState.category) ||
            undefined,
        },
        refinementList: {
          author: routeState.author,
          stock_status: routeState.stock_status,
          'taxonomies.pub': routeState['taxonomies_pub'],
          'taxonomies.pa_format': routeState['pa_format'],
        },
        range: {
          price: routeState.price,
          release_date_timestamp: routeState.release_date_timestamp,
        },
        sortBy: routeState.sortBy,
        hitsPerPage: Number(routeState.hitsPerPage),
      },
    };

    return response;
  },
});

const getRouting = ({ indexName }) => ({
  router,
  stateMapping: getStateMapping({ indexName }),
});

export default getRouting;
