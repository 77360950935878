"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateFragments = void 0;
function updateFragments(data) {
    if (data.fragments) {
        jQuery.each(data.fragments, function (key, value) {
            /** @ts-ignore **/
            jQuery(key).replaceWith(value);
        });
    }
    jQuery('body').trigger('wc_fragments_refreshed');
}
exports.updateFragments = updateFragments;
