<template>
  <ais-instant-search
    class="algolia-search"
    :class="{'algolia-search-grid': gridView, 'algolia-search-list': !gridView}"
    :search-client="searchClient"
    index-name="wp_posts_product"
    :routing="routing"
  >
    <ais-configure :filters="filters"/>
    <div class="ais-header-search">
      <div class="container">
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-6">
            <h2 class="text-center text-white">Find a product</h2>
            <ais-search-box id="global-search-input" placeholder="Title, Author, ISBN" :stalled-search-delay="200" show-loading-indicator>
              <template v-slot:submit-icon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 18 18"
                  width="16"
                  height="16"
                >
                  <g
                    fill="none"
                    fill-rule="evenodd"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.67"
                    transform="translate(1 1)"
                  >
                    <circle
                      cx="7.11"
                      cy="7.11"
                      r="7.11"
                    ></circle>
                    <path d="M16 16l-3.87-3.87"></path>
                  </g>
                </svg>
              </template>
            </ais-search-box>
          </div>
        </div>
      </div>

    </div>

    <!--
    search inputs
    Client: Are we able to change the filter order to: Category, Author, Publisher, Availability, Format, Release Date, Price Range
     -->
    <main class="algolia-container"     :class="{ 'filtering' : filtering }">
      <div class="container-wrapper">
          <section class="container-filters">
            <div class="container-header">
              <h2>Filters</h2>

              <!-- Clear refinements button -->
              <ais-clear-refinements data-layout="desktop">
                <template v-slot:resetLabel>
                  <div class="clear-filters">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 11 11"
                    >
                      <g
                        fill="none"
                        fill-rule="evenodd"
                        opacity=".4"
                      >
                        <path d="M0 0h11v11H0z"></path>
                        <path
                          fill="#000"
                          fill-rule="nonzero"
                          d="M8.26 2.75a3.896 3.896 0 1 0 1.102 3.262l.007-.056a.49.49 0 0 1 .485-.456c.253 0 .451.206.437.457 0 0 .012-.109-.006.061a4.813 4.813 0 1 1-1.348-3.887v-.987a.458.458 0 1 1 .917.002v2.062a.459.459 0 0 1-.459.459H7.334a.458.458 0 1 1-.002-.917h.928z"
                        ></path>
                      </g>
                    </svg>
                    Clear filters
                  </div>
                </template>
              </ais-clear-refinements>

              <!-- display results num -->
              <ais-stats data-layout="mobile">
                <template v-slot="{ nbHits }">
                <span class="ais-Stats-text">
                  <strong>{{ formatNumber(nbHits) }}</strong> results
                </span>
                </template>
              </ais-stats>
            </div>

            <div class="container-body">
              <!-- Categories -->
              <ais-panel>
                <template v-slot:header>Category</template>
                <template v-slot:default>
                  <ais-hierarchical-menu :limit="500" :attributes="[
                  'taxonomies_hierarchical.product_cat.lvl0',
                  'taxonomies_hierarchical.product_cat.lvl1',
                  'taxonomies_hierarchical.product_cat.lvl2'
                ]" />
                </template>
              </ais-panel>

              <!-- Author -->
              <ais-panel>
                <template v-slot:header>
                  Author
                </template>
                <template v-slot:default>
                  <ais-refinement-list
                    attribute="author"
                    operator="or"
                    searchable
                    searchablePlaceholder="Search for authors…"
                    show-more
                  />
                </template>
              </ais-panel>

              <!-- Publisher -->
              <ais-panel>
                <template v-slot:header>
                  Publisher
                </template>
                <template v-slot:default>
                  <ais-refinement-list
                    attribute="taxonomies.pub"
                    operator="or"
                    searchable
                    searchablePlaceholder="Search for publishers…"
                    show-more
                  />
                </template>
              </ais-panel>

              <!-- Availability -->
              <ais-panel>
                <template v-slot:header>
                  Availability
                </template>
                <template v-slot:default>
                  <ais-refinement-list
                    attribute="stock_status"
                    operator="or"
                    show-more
                  />
                </template>
              </ais-panel>

              <!-- Format -->
              <ais-panel>
                <template v-slot:header>
                  Format
                </template>
                <template v-slot:default>
                  <ais-refinement-list
                    attribute="taxonomies.pa_format"
                    operator="or"
                    show-more
                  />
                </template>
              </ais-panel>

              <!-- Date Range -->
              <ais-panel>
                <template v-slot:header>Release Date</template>

                <template v-slot:default>
                  <date-range-picker attribute="release_date_timestamp"/>
                </template>
              </ais-panel>

              <!-- Price -->
              <ais-panel>
                <template v-slot:header>Price</template>

                <template v-slot:default>
                  <ais-range-input attribute="price">
                    <template v-slot="{ currentRefinement, range, refine, canRefine }">
                      <vue-slider
                        :min="range.min"
                        :max="range.max"
                        :value="toValue(currentRefinement, range)"
                        :disabled="!canRefine"
                        :lazy="true"
                        :useKeyboard="true"
                        :enableCross="false"
                        tooltip="always"
                        :duration="0"
                        @change="refine({ min: $event[0], max: $event[1] })"
                      >
                        <template v-slot:dot="{ index, value }">
                          <div
                            :aria-valuemin="range.min"
                            :aria-valuemax="range.max"
                            :aria-valuenow="value"
                            :data-handle-key="index"
                            class="vue-slider-dot-handle"
                            role="slider"
                            tabindex="0"
                          />
                        </template>
                        <template v-slot:tooltip="{ value }">
                          {{ formatNumber(value) }}
                        </template>
                      </vue-slider>
                    </template>
                  </ais-range-input>
                </template>
              </ais-panel>
            </div>
          </section>
          <footer
            class="container-filters-footer"
            data-layout="mobile"
          >
            <clear-refinement
              class="container-filters-footer-button-wrapper"
              @click="closeFilters"
            />

            <ais-stats class="container-filters-footer-button-wrapper">
              <template v-slot="{ nbHits }">
                <button
                  class="button button-primary"
                  @click="closeFilters"
                >
                  See {{ formatNumber(nbHits) }} results
                </button>
              </template>
            </ais-stats>
          </footer>
      </div>

      <!-- Results -->
      <section class="container-results">
        <header class="container-header container-options">
          <ais-sort-by
            class="container-option"
            :items="[
            { value: 'wp_posts_product', label: 'Default' },
            // { value: 'wp_posts_product_price_asc', label: 'Price ascending' },
            // { value: 'wp_posts_product_price_desc', label: 'Price descending' },
            { value: 'wp_posts_product_release_date_timestamp_asc', label: 'Release date latest' },
            // { value: 'wp_posts_product_release_date_timestamp_desc', label: 'Release date oldest' },
          ]"
          />

          <ais-hits-per-page
            class="container-option"
            :items="[
            {
              label: '16 hits per page',
              value: 16,
              default: getSelectedHitsPerPageValue() === 16 || !getSelectedHitsPerPageValue(),
            },
            {
              label: '32 hits per page',
              value: 32,
              default: getSelectedHitsPerPageValue() === 32,
            },
            {
              label: '64 hits per page',
              value: 64,
              default: getSelectedHitsPerPageValue() === 64,
            },
          ]"
          />

          <a class="ais-display-switcher" @click="toggleList">
            <i class="fas fa-th-large" v-if="!gridView" title="Enable grid view"></i>
            <i class="fas fa-list"  v-if="gridView" title="Enable list view"></i>
          </a>

          <a @click="print" class="ais-print">
            <i class="fas fa-print" title="Print page"></i>
          </a>
        </header>

        <ais-hits>
          <template v-slot:item="{ item }">
            <hit-long v-if="item && !gridView" :item="item"></hit-long>
            <hit-grid v-if="item && gridView" :item="item"></hit-grid>
          </template>
        </ais-hits>
        <no-results />
        <footer class="container-footer">
          <ais-pagination :padding="2">
            <template v-slot="{
                  currentRefinement,
                  pages,
                  isFirstPage,
                  isLastPage,
                  refine,
                  createURL
                }">
              <ul class="ais-Pagination-list">
                <li
                  class="ais-Pagination-item ais-Pagination-item--previousPage ais-Pagination-item--disabled"
                  v-if="isFirstPage"
                >
                    <span class="ais-Pagination-link">
                      Prev
                    </span>
                </li>

                <li
                  class="ais-Pagination-item ais-Pagination-item--previousPage"
                  v-if="!isFirstPage"
                >
                  <a
                    class="ais-Pagination-link"
                    :href="createURL(currentRefinement - 1)"
                    @click.prevent="refine(currentRefinement - 1)"
                    aria-label="Previous"
                  >
                    Prev
                  </a>
                </li>

                <li
                  :class="cx('ais-Pagination-item', 'ais-Pagination-item--page', {
                      'ais-Pagination-item--selected': page === currentRefinement
                    })"
                  v-for="page in pages"
                  :key="page"
                >
                  <a
                    class="ais-Pagination-link"
                    :href="createURL(page)"
                    @click.prevent="refine(page)"
                  >{{ page + 1 }}</a>
                </li>

                <li
                  class="ais-Pagination-item ais-Pagination-item--nextPage"
                  v-if="!isLastPage"
                >
                  <a
                    class="ais-Pagination-link"
                    :href="createURL(currentRefinement + 1)"
                    @click.prevent="refine(currentRefinement + 1)"
                    aria-label="Next"
                  >
                    Next
                  </a>
                </li>

                <li
                  class="ais-Pagination-item ais-Pagination-item--nextPage ais-Pagination-item--disabled"
                  v-if="isLastPage"
                >
                    <span class="ais-Pagination-link">
                      Next
                    </span>
                </li>
              </ul>
            </template>
          </ais-pagination>
        </footer>

      </section>
    </main>

    <!-- Mobile Filter -->
    <aside data-layout="mobile">
      <button
        class="filters-button"
        @click="openFilters"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewbox="0 0 16 14"
        >
          <path
            d="M15 1H1l5.6 6.3v4.37L9.4 13V7.3z"
            stroke="#fff"
            stroke-width="1.29"
            fill="none"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        Filters
      </button>
    </aside>

  </ais-instant-search>
</template>

<script>
// Mostly stolen from https://github.com/algolia/vue-instantsearch/blob/master/examples/e-commerce/src
import algoliasearch from 'algoliasearch/lite';
import VueSlider from 'vue-slider-component';
import { formatNumber } from '../../../utils/formatNumber';
import DateRangePicker from './widgets/DateRangePicker';
import getRouting from './routing';
import cx from 'classnames';

import HitLong from "./widgets/HitLong";
import ClearRefinement from "./widgets/ClearRefinement";
import NoResults from "./widgets/NoResults";
import HitGrid from "./widgets/HitGrid";
import Datepicker from 'vuejs-datepicker';

export default {
  components: {
    HitGrid,
    HitLong,
    VueSlider,
    DateRangePicker,
    ClearRefinement,
    NoResults,
    Datepicker
  },
  mounted() {
    this.resultsContainer = document.querySelector('.container-results');
    this.header = document.querySelector('#header');

    this.windowWidth = window.innerWidth
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })

  },
  created() {
    this.onKeyUp = (event) => {
      if (event.key !== 'Escape') {
        return;
      }
      this.closeFilters();
    };
    this.onClick = (event) => {
      if (event.target !== this.header) {
        return;
      }
      this.closeFilters();
    };

    let view = window.localStorage.getItem('BRUMBY_SEARCH_LIST_VIEW');

    if(view === "false") {
      this.list = false;
    } else {
      this.list = true;
    }

  },
  methods: {
    print() {
      window.print();
    },
    formatNumber,
    toValue(value, range) {
      return [
        typeof value.min === 'number' ? value.min : range.min,
        typeof value.max === 'number' ? value.max : range.max,
      ];
    },
    getSelectedHitsPerPageValue() {
      const [, hitsPerPage] =
      document.location.search.match(/hitsPerPage=([0-9]+)/) || [];
      return Number(hitsPerPage);
    },
    openFilters() {
      this.filtering = true;
      document.body.classList.add('filtering');
      window.scrollTo(0, 0);
      window.addEventListener('keyup', this.onKeyUp);
      window.addEventListener('click', this.onClick);
    },
    closeFilters() {
      this.filtering = false;
      document.body.classList.remove('filtering');
      this.resultsContainer.scrollIntoView();
      window.removeEventListener('keyup', this.onKeyUp);
      window.removeEventListener('click', this.onClick);
    },
    toggleList() {
      let list = !this.list
      this.list = list;
      window.localStorage.setItem('BRUMBY_SEARCH_LIST_VIEW', list);

      let view = window.localStorage.getItem('BRUMBY_SEARCH_LIST_VIEW');
      console.log(view);
    },
    formatMinValue(minValue, minRange) {
      return minValue !== null && minValue !== minRange ? minValue : '';
    },
    formatMaxValue(maxValue, maxRange) {
      return maxValue !== null && maxValue !== maxRange ? maxValue : '';
    },

  },
  computed: {
    filters() {
      let includeSpecials = document.body.dataset.specials === "1";

      console.log(includeSpecials)



      let ids = [];
      if(window.brumby_filter_ids) {
        window.brumby_filter_ids.forEach((element) => {
          ids.push('objectID:' + element + '-0');
        })
      }

      let query = `${ids.join(' OR ')}`

      if(!includeSpecials && query) {
        query = `${query} AND is_special:false`
      } else if(!includeSpecials) {
        query = `is_special:false`
      }

      return query;
    },
    gridView() {
      return !this.list || this.windowWidth < 900;
    }
  },
  data() {
    return {
      windowWidth: 0,
      cx,
      filtering: false,
      searchClient: algoliasearch(
        'F2QAAJNIRC',
        'eaace5857dab7f267f5c6d48bd06bd3f'
      ),
      routing: getRouting({ indexName: 'wp_posts_product' }),
      list: true,
    };
  }
}
</script>

<style lang="scss">
#ais-wrapper {
  margin: 0 auto;
}


.algolia-search {
  @import './App.scss';
  @import './GlobalSearch.scss';
  @import './App.mobile.scss';
  @import './Theme.scss';
  @import './widgets/VueSlider.scss';
  @import './widgets/HitGrid.scss';
  @import './widgets/HitLong.scss';
  @import './widgets/DateRangePicker.scss';
  @import './widgets/AddToCart.scss';
}
</style>
