import { render, staticRenderFns } from "./NoResults.vue?vue&type=template&id=7301a3d0&"
import script from "./NoResults.vue?vue&type=script&lang=js&"
export * from "./NoResults.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/nathan/code/brumby-sunstate/theme/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7301a3d0')) {
      api.createRecord('7301a3d0', component.options)
    } else {
      api.reload('7301a3d0', component.options)
    }
    module.hot.accept("./NoResults.vue?vue&type=template&id=7301a3d0&", function () {
      api.rerender('7301a3d0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/scripts/components/vue/instantsearch/widgets/NoResults.vue"
export default component.exports