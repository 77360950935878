<template>
  <div class="d-flex flex-column">
    <h3 class="underline">Order #{{ this.invoice }}</h3>

    <div class="fa-3x d-flex mt-5" v-if="!invoiceHistory">
      <i class="fas fa-circle-notch fa-spin mx-auto "></i>
    </div>

    <div class="mt-5" v-if="invoiceHistory.line_items">
      <p>Invoice Number #{{ this.invoice }} as placed on {{ invoiceHistory.invoice_date }}</p>
    </div>
    <h4 v-if="invoiceHistory.line_items">Items in order</h4>
    <div class="table-responsive" v-if="invoiceHistory.line_items">
      <table class="table">
        <thead>
          <th>ISBN</th>
          <th>Product</th>
          <th>Qty Supplied</th>
          <th>Line Total</th>
        </thead>
        <tbody>
        <tr v-for="line_item in invoiceHistory.line_items">
          <td>{{ line_item.isbn }}</td>
          <td>
            <a target="_blank" :href="line_item.url">
              {{ line_item.title }}
            </a>
          </td>
          <td>{{ line_item.qty_supplied }}</td>
          <td>${{ lineTotal(line_item) }}</td>
        </tr>
        <tr>
          <th colspan="2"></th>
          <th>Total GST</th>
          <td>${{ invoiceHistory.invoice_total_gst}}</td>
        </tr>
        <tr>
          <th colspan="2"></th>
          <th>Order Total</th>
          <td>${{ invoiceHistory.invoice_total_incgst }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

export default {
  name: "App",
  props: ['invoice'],
  data() {
    return {
      invoiceHistory: false
    }
  },
  created() {
    this.setup();
  },
  methods: {
    lineTotal(line_item) {
      return ((parseFloat(line_item.unit_gst) + parseFloat(line_item.unit_value_exgst)) * parseFloat(line_item.qty_supplied)).toFixed(2)
    },
    setup() {
      jQuery.post(window.wc_add_to_cart_params.ajax_url, {
        action: 'ajax_invoice_lines',
        invoice_id: this.invoice
      }, (data, status) => {
        this.invoiceHistory = JSON.parse(data);
      })
    }
  }
}
</script>

<style scoped>

</style>
