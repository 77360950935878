"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const component_1 = require("../packages/component-framework/component");
const core_1 = __importStar(require("swiper/core"));
core_1.default.use([core_1.Navigation, core_1.Pagination]);
class ProductCarousel extends component_1.Component {
    constructor(element) {
        super(element);
        this.registerBanner();
    }
    registerBanner() {
        const slideCount = this.element.getElementsByClassName('swiper-slide').length;
        const slider = this.element.querySelector('.swiper');
        const nextElement = this.element.querySelector('.navigation-arrows-right');
        const prevElement = this.element.querySelector('.navigation-arrows-left');
        new core_1.default(slider, {
            loop: slideCount > 1,
            slidesPerView: 6,
            spaceBetween: 30,
            navigation: {
                nextEl: nextElement,
                prevEl: prevElement,
            },
            breakpoints: {
                0: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 3,
                },
                1366: {
                    slidesPerView: 6,
                },
            },
        });
    }
}
exports.default = ProductCarousel;
ProductCarousel.selector = '.module-product_carousel';
