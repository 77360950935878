"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const component_1 = require("../packages/component-framework/component");
class Banner extends component_1.Component {
    constructor(element) {
        super(element);
        window.addEventListener('scroll', () => {
            if (window.scrollY > 50) {
                this.element.classList.add('stuck');
                document.querySelectorAll('.header-fill').forEach((el) => {
                    el.setAttribute('style', 'height: 150px;');
                });
            }
            else {
                this.element.classList.remove('stuck');
                document.querySelectorAll('.header-fill').forEach((el) => {
                    el.removeAttribute('style');
                });
            }
        });
    }
}
exports.default = Banner;
Banner.selector = 'header.banner';
