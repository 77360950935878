<template>
  <div class="algolia-datepicker-container">
    <slot
      :current-refinement="values"
      :refine="refine"
      :can-refine="canRefine"
    >
      <span class="algolia-datepicker">
        <span class="algolia-datepicker-overlay">
            <i class="fal fa-calendar"></i>
            <p v-if="!minInputValue && !minInput">Start Date</p>
        </span>
        <datepicker :value="minInputValue" @selected="minInputSelected"/>
      </span>
      <span class="algolia-datepicker">
        <span class="algolia-datepicker-overlay">
            <i class="fal fa-calendar"></i>
            <p v-if="!maxInputValue && !maxInput">End Date</p>
        </span>
        <datepicker :value="maxInputValue" @selected="maxInputSelected"/>
      </span>
    </slot>
  </div>
</template>

<script>

import Datepicker from 'vuejs-datepicker';
import { connectRange, connectCurrentRefinements } from 'instantsearch.js/es/connectors';
import { createWidgetMixin } from 'vue-instantsearch/src/mixins/widget';
import { createPanelConsumerMixin } from 'vue-instantsearch/src/mixins/panel';
import { createSuitMixin } from 'vue-instantsearch/src/mixins/suit';

const mapStateToCanRefine = state =>
  state && Boolean(state.range) && state.range.min !== state.range.max;

const mapStateToValues = state =>
  state && state.start && {
    min: state.start[0],
    max: state.start[1],
  };

export default {
  components: {
    Datepicker
  },
  mixins: [
    createSuitMixin({ name: 'RangeInput' }),
    createWidgetMixin({ connector: connectRange }),
    createPanelConsumerMixin({
      mapStateToCanRefine,
      mapStateToValues
    }),
  ],
  props: {
    attribute: {
      type: String,
      required: true,
    },
    min: {
      type: Number,
      required: false,
      default: undefined,
    },
    max: {
      type: Number,
      required: false,
      default: undefined,
    },
    precision: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      minInputValue: null,
      maxInputValue: null,
      minInput: undefined,
      maxInput: undefined,
      initialValuesSet: false
    };
  },
  computed: {
    widgetParams() {
      return {
        attribute: this.attribute,
        min: this.min,
        max: this.max,
        precision: this.precision,
      };
    },
    otherValues() {
      return mapStateToValues(this.state);
    },
    canRefine() {
      return mapStateToCanRefine(this.state);
    },
    step() {
      return 1 / Math.pow(10, this.precision);
    },
    values() {
      return {
        min: this.minInput,
        max: this.maxInput
      };
    },
  },
  watch: {
    otherValues(oldOtherValues, newOtherValues) {
      if(this.initialValuesSet) {
        return;
      }

      // make sure both are numbers
      if (isNaN(oldOtherValues.min) && isNaN(oldOtherValues.max)) {
        return;
      }

      if(!isFinite(oldOtherValues.min) && !isFinite(oldOtherValues.max)) {
        return;
      }

      console.log(oldOtherValues, newOtherValues, isNaN(oldOtherValues.min), isNaN(oldOtherValues.max), isFinite(oldOtherValues.min), isFinite(oldOtherValues.max));

      if(oldOtherValues.min && (!oldOtherValues.max || isNaN(oldOtherValues.max))) {
        oldOtherValues.max = oldOtherValues.min;
      }

      if(oldOtherValues.max && (!oldOtherValues.min || isNaN(oldOtherValues.min))) {
        oldOtherValues.min = oldOtherValues.max;
      }

      if(oldOtherValues.min && !isNaN(oldOtherValues.min) && isFinite(oldOtherValues.min)) {
        this.minInputValue = new Date(oldOtherValues.min * 1000);
        this.minInput = new Date(oldOtherValues.min * 1000).getTime() / 1000
      }

      if(oldOtherValues.max && !isNaN(oldOtherValues.max) && isFinite(oldOtherValues.max)) {
        this.maxInputValue = new Date(oldOtherValues.max * 1000);
        this.maxInput = new Date(oldOtherValues.max * 1000).getTime() / 1000
      }

      this.initialValuesSet = true;
    }
  },
  mounted() {
    console.log(this.otherValues);
  },
  methods: {
    minInputSelected(e) {
      this.minInput = new Date(e).getTime() / 1000

      if(!this.maxInput) {
        this.maxInput = this.minInput;
      }

      this.refine({ min: this.minInput, max: this.maxInput })
    },
    maxInputSelected(e) {
      this.maxInput = new Date(e).getTime() / 1000

      if(!this.minInput) {
        this.minInput = this.maxInput;
      }

      this.refine({ min: this.minInput, max: this.maxInput })
    },
    pick(first, second) {
      if (first !== null && first !== undefined) {
        return first;
      } else {
        return second;
      }
    },
    refine({ min, max }) {
      this.state.refine([min, max]);
    },
  },
}
</script>

<style scoped>

</style>
