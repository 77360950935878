<template>
    <div class="add-to-cart-component" v-if="isLoggedIn">
      <div class="ais-hits--content" v-if="showDetails">
        <p itemprop="name headline">
          <a :href="item.permalink.replace('brumby.test', 'brumby-staging.dev.fweb.com.au')"
             :title="item.post_title" class="ais-hits--title-link" itemprop="url" v-html="item._highlightResult.post_title.value">
          </a>
        </p>
        <p class="secondary">
          {{ item.author }}
        </p>
        <div class="price-wrapper" v-if="item.price">
          <p class="price">
            ${{ price(item) }}
          </p>
        </div>
      </div>

      <div class="quantity-selector">
        <label class="sr-only" :for="'product-quantity-selector-' + postId">
          Quantity
        </label>
        <input type="number" v-model="quantity" :id="'product-quantity-selector-' + postId" class="product-quantity-selector">
      </div>

      <span>
      <a class="btn btn-brumby-3 add-to-cart" @click="addToCart" :disabled="sending">
        Add to cart
        <i class="fal fa-plus-circle" v-if="!sending && !sent"></i>
        <i class="fas fa-spinner-third fa-spin" v-if="sending && !sent"></i>
        <i class="fal fa-check-circle" v-if="!sending && sent"></i>
      </a>
    </span>
    </div>
    <div class="add-to-cart-component ms-auto" v-else="!isLoggedIn">
      <a href="#login" @click="openLogin">
        Login To See Prices
      </a>
    </div>
</template>

<script>
import {updateFragments} from "../../../../woocommerce/fragmentHandler";

export default {
  name: "AddToCart",
  props: {
    postId: {
      type: Number,
      required: false,
    },
    item: {
      type: Object,
      required: false,
    },
    showDetails: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      quantity: 1,
      sending: false,
      sent: false
    }
  },
  computed: {
    isLoggedIn() {
      return document.body.classList.contains( 'logged-in' );
    }
  },
  methods: {
    openLogin() {
      window.openLoginModal();
    },
    price(item) {
      const discount = parseFloat(document.body.dataset.discount);
      return (item.price - (item.price * discount / 100)).toFixed(2);
    },
    addToCart() {
      const data = {
        product_id: this.postId,
        quantity: this.quantity,
      }

      this.sending = true;


      jQuery.post(
        wc_add_to_cart_params.wc_ajax_url.toString().replace( '%%endpoint%%', 'add_to_cart' ),
        data,
        (data, status) => {
        if (data.fragments) {
          updateFragments(data);
        }
        this.sending = false;
        this.sent = true;
        setTimeout(() => {
          this.sent = false;
        }, 1500);
      })
    }
  },
}
</script>

<style scoped>

</style>
