var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ais-instant-search",
    {
      staticClass: "algolia-search",
      class: {
        "algolia-search-grid": _vm.gridView,
        "algolia-search-list": !_vm.gridView,
      },
      attrs: {
        "search-client": _vm.searchClient,
        "index-name": "wp_posts_product",
        routing: _vm.routing,
      },
    },
    [
      _c("ais-configure", { attrs: { filters: _vm.filters } }),
      _vm._v(" "),
      _c("div", { staticClass: "ais-header-search" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-3" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c("h2", { staticClass: "text-center text-white" }, [
                  _vm._v("Find a product"),
                ]),
                _vm._v(" "),
                _c("ais-search-box", {
                  attrs: {
                    id: "global-search-input",
                    placeholder: "Title, Author, ISBN",
                    "stalled-search-delay": 200,
                    "show-loading-indicator": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "submit-icon",
                      fn: function () {
                        return [
                          _c(
                            "svg",
                            {
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 18 18",
                                width: "16",
                                height: "16",
                              },
                            },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    fill: "none",
                                    "fill-rule": "evenodd",
                                    stroke: "currentColor",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round",
                                    "stroke-width": "1.67",
                                    transform: "translate(1 1)",
                                  },
                                },
                                [
                                  _c("circle", {
                                    attrs: {
                                      cx: "7.11",
                                      cy: "7.11",
                                      r: "7.11",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("path", {
                                    attrs: { d: "M16 16l-3.87-3.87" },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "main",
        {
          staticClass: "algolia-container",
          class: { filtering: _vm.filtering },
        },
        [
          _c("div", { staticClass: "container-wrapper" }, [
            _c("section", { staticClass: "container-filters" }, [
              _c(
                "div",
                { staticClass: "container-header" },
                [
                  _c("h2", [_vm._v("Filters")]),
                  _vm._v(" "),
                  _c("ais-clear-refinements", {
                    attrs: { "data-layout": "desktop" },
                    scopedSlots: _vm._u([
                      {
                        key: "resetLabel",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "clear-filters" }, [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "10",
                                    height: "10",
                                    viewBox: "0 0 11 11",
                                  },
                                },
                                [
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        fill: "none",
                                        "fill-rule": "evenodd",
                                        opacity: ".4",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: { d: "M0 0h11v11H0z" },
                                      }),
                                      _vm._v(" "),
                                      _c("path", {
                                        attrs: {
                                          fill: "#000",
                                          "fill-rule": "nonzero",
                                          d: "M8.26 2.75a3.896 3.896 0 1 0 1.102 3.262l.007-.056a.49.49 0 0 1 .485-.456c.253 0 .451.206.437.457 0 0 .012-.109-.006.061a4.813 4.813 0 1 1-1.348-3.887v-.987a.458.458 0 1 1 .917.002v2.062a.459.459 0 0 1-.459.459H7.334a.458.458 0 1 1-.002-.917h.928z",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(
                                "\n                  Clear filters\n                "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("ais-stats", {
                    attrs: { "data-layout": "mobile" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var nbHits = ref.nbHits
                          return [
                            _c("span", { staticClass: "ais-Stats-text" }, [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.formatNumber(nbHits))),
                              ]),
                              _vm._v(" results\n              "),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "container-body" },
                [
                  _c("ais-panel", {
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function () {
                          return [_vm._v("Category")]
                        },
                        proxy: true,
                      },
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c("ais-hierarchical-menu", {
                              attrs: {
                                limit: 500,
                                attributes: [
                                  "taxonomies_hierarchical.product_cat.lvl0",
                                  "taxonomies_hierarchical.product_cat.lvl1",
                                  "taxonomies_hierarchical.product_cat.lvl2",
                                ],
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("ais-panel", {
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _vm._v("\n                Author\n              "),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c("ais-refinement-list", {
                              attrs: {
                                attribute: "author",
                                operator: "or",
                                searchable: "",
                                searchablePlaceholder: "Search for authors…",
                                "show-more": "",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("ais-panel", {
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n                Publisher\n              "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c("ais-refinement-list", {
                              attrs: {
                                attribute: "taxonomies.pub",
                                operator: "or",
                                searchable: "",
                                searchablePlaceholder: "Search for publishers…",
                                "show-more": "",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("ais-panel", {
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n                Availability\n              "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c("ais-refinement-list", {
                              attrs: {
                                attribute: "stock_status",
                                operator: "or",
                                "show-more": "",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("ais-panel", {
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _vm._v("\n                Format\n              "),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c("ais-refinement-list", {
                              attrs: {
                                attribute: "taxonomies.pa_format",
                                operator: "or",
                                "show-more": "",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("ais-panel", {
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function () {
                          return [_vm._v("Release Date")]
                        },
                        proxy: true,
                      },
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c("date-range-picker", {
                              attrs: { attribute: "release_date_timestamp" },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("ais-panel", {
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function () {
                          return [_vm._v("Price")]
                        },
                        proxy: true,
                      },
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c("ais-range-input", {
                              attrs: { attribute: "price" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var currentRefinement =
                                      ref.currentRefinement
                                    var range = ref.range
                                    var refine = ref.refine
                                    var canRefine = ref.canRefine
                                    return [
                                      _c("vue-slider", {
                                        attrs: {
                                          min: range.min,
                                          max: range.max,
                                          value: _vm.toValue(
                                            currentRefinement,
                                            range
                                          ),
                                          disabled: !canRefine,
                                          lazy: true,
                                          useKeyboard: true,
                                          enableCross: false,
                                          tooltip: "always",
                                          duration: 0,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return refine({
                                              min: $event[0],
                                              max: $event[1],
                                            })
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "dot",
                                              fn: function (ref) {
                                                var index = ref.index
                                                var value = ref.value
                                                return [
                                                  _c("div", {
                                                    staticClass:
                                                      "vue-slider-dot-handle",
                                                    attrs: {
                                                      "aria-valuemin":
                                                        range.min,
                                                      "aria-valuemax":
                                                        range.max,
                                                      "aria-valuenow": value,
                                                      "data-handle-key": index,
                                                      role: "slider",
                                                      tabindex: "0",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                            {
                                              key: "tooltip",
                                              fn: function (ref) {
                                                var value = ref.value
                                                return [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(
                                                        _vm.formatNumber(value)
                                                      ) +
                                                      "\n                      "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "footer",
              {
                staticClass: "container-filters-footer",
                attrs: { "data-layout": "mobile" },
              },
              [
                _c("clear-refinement", {
                  staticClass: "container-filters-footer-button-wrapper",
                  on: { click: _vm.closeFilters },
                }),
                _vm._v(" "),
                _c("ais-stats", {
                  staticClass: "container-filters-footer-button-wrapper",
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var nbHits = ref.nbHits
                        return [
                          _c(
                            "button",
                            {
                              staticClass: "button button-primary",
                              on: { click: _vm.closeFilters },
                            },
                            [
                              _vm._v(
                                "\n                See " +
                                  _vm._s(_vm.formatNumber(nbHits)) +
                                  " results\n              "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "section",
            { staticClass: "container-results" },
            [
              _c(
                "header",
                { staticClass: "container-header container-options" },
                [
                  _c("ais-sort-by", {
                    staticClass: "container-option",
                    attrs: {
                      items: [
                        { value: "wp_posts_product", label: "Default" },
                        // { value: 'wp_posts_product_price_asc', label: 'Price ascending' },
                        // { value: 'wp_posts_product_price_desc', label: 'Price descending' },
                        {
                          value: "wp_posts_product_release_date_timestamp_asc",
                          label: "Release date latest",
                        },
                      ],
                    },
                  }),
                  _vm._v(" "),
                  _c("ais-hits-per-page", {
                    staticClass: "container-option",
                    attrs: {
                      items: [
                        {
                          label: "16 hits per page",
                          value: 16,
                          default:
                            _vm.getSelectedHitsPerPageValue() === 16 ||
                            !_vm.getSelectedHitsPerPageValue(),
                        },
                        {
                          label: "32 hits per page",
                          value: 32,
                          default: _vm.getSelectedHitsPerPageValue() === 32,
                        },
                        {
                          label: "64 hits per page",
                          value: 64,
                          default: _vm.getSelectedHitsPerPageValue() === 64,
                        },
                      ],
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "ais-display-switcher",
                      on: { click: _vm.toggleList },
                    },
                    [
                      !_vm.gridView
                        ? _c("i", {
                            staticClass: "fas fa-th-large",
                            attrs: { title: "Enable grid view" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.gridView
                        ? _c("i", {
                            staticClass: "fas fa-list",
                            attrs: { title: "Enable list view" },
                          })
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    { staticClass: "ais-print", on: { click: _vm.print } },
                    [
                      _c("i", {
                        staticClass: "fas fa-print",
                        attrs: { title: "Print page" },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("ais-hits", {
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        item && !_vm.gridView
                          ? _c("hit-long", { attrs: { item: item } })
                          : _vm._e(),
                        _vm._v(" "),
                        item && _vm.gridView
                          ? _c("hit-grid", { attrs: { item: item } })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("no-results"),
              _vm._v(" "),
              _c(
                "footer",
                { staticClass: "container-footer" },
                [
                  _c("ais-pagination", {
                    attrs: { padding: 2 },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var currentRefinement = ref.currentRefinement
                          var pages = ref.pages
                          var isFirstPage = ref.isFirstPage
                          var isLastPage = ref.isLastPage
                          var refine = ref.refine
                          var createURL = ref.createURL
                          return [
                            _c(
                              "ul",
                              { staticClass: "ais-Pagination-list" },
                              [
                                isFirstPage
                                  ? _c(
                                      "li",
                                      {
                                        staticClass:
                                          "ais-Pagination-item ais-Pagination-item--previousPage ais-Pagination-item--disabled",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "ais-Pagination-link",
                                          },
                                          [
                                            _vm._v(
                                              "\n                    Prev\n                  "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !isFirstPage
                                  ? _c(
                                      "li",
                                      {
                                        staticClass:
                                          "ais-Pagination-item ais-Pagination-item--previousPage",
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "ais-Pagination-link",
                                            attrs: {
                                              href: createURL(
                                                currentRefinement - 1
                                              ),
                                              "aria-label": "Previous",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return refine(
                                                  currentRefinement - 1
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  Prev\n                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._l(pages, function (page) {
                                  return _c(
                                    "li",
                                    {
                                      key: page,
                                      class: _vm.cx(
                                        "ais-Pagination-item",
                                        "ais-Pagination-item--page",
                                        {
                                          "ais-Pagination-item--selected":
                                            page === currentRefinement,
                                        }
                                      ),
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "ais-Pagination-link",
                                          attrs: { href: createURL(page) },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return refine(page)
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(page + 1))]
                                      ),
                                    ]
                                  )
                                }),
                                _vm._v(" "),
                                !isLastPage
                                  ? _c(
                                      "li",
                                      {
                                        staticClass:
                                          "ais-Pagination-item ais-Pagination-item--nextPage",
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "ais-Pagination-link",
                                            attrs: {
                                              href: createURL(
                                                currentRefinement + 1
                                              ),
                                              "aria-label": "Next",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return refine(
                                                  currentRefinement + 1
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  Next\n                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                isLastPage
                                  ? _c(
                                      "li",
                                      {
                                        staticClass:
                                          "ais-Pagination-item ais-Pagination-item--nextPage ais-Pagination-item--disabled",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "ais-Pagination-link",
                                          },
                                          [
                                            _vm._v(
                                              "\n                    Next\n                  "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("aside", { attrs: { "data-layout": "mobile" } }, [
        _c(
          "button",
          { staticClass: "filters-button", on: { click: _vm.openFilters } },
          [
            _c(
              "svg",
              {
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  viewbox: "0 0 16 14",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M15 1H1l5.6 6.3v4.37L9.4 13V7.3z",
                    stroke: "#fff",
                    "stroke-width": "1.29",
                    fill: "none",
                    "fill-rule": "evenodd",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                  },
                }),
              ]
            ),
            _vm._v("\n\n      Filters\n    "),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }