var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.state
    ? _c("div", { staticClass: "ais-ClearRefinements" }, [
        _c(
          "button",
          {
            staticClass: "ais-ClearRefinements-button",
            attrs: { type: "reset" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.refine.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n    Reset filters\n  ")]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }