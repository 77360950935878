<template>
  <div>
    <h3 class="underline">Your Invoice History</h3>

    <div class="fa-3x d-flex mt-5" v-if="!invoiceHistory">
      <i class="fas fa-circle-notch fa-spin mx-auto "></i>
    </div>
    <div class="table-responsive mt-5" v-if="invoiceHistory">
      <table class="table">
        <thead>
          <th>Invoice</th>
          <th>Date</th>
          <th>Order Status</th>
          <th>Invoice Amount</th>
          <th>Actions</th>
        </thead>
        <tbody>
          <tr v-if="!invoiceHistory">
            <td colspan="5">No invoices found</td>
          </tr>
          <tr v-for="invoice in invoiceHistory">
            <td>#{{ invoice.omnis_Invoice}}</td>
            <td>{{ invoice.invoice_date }}</td>
            <td>{{ invoice.status }}</td>
            <td>${{ invoice.invoice_amount }}</td>
            <td>
              <a :href="'/my-account/invoices/' + invoice.omnis_Invoice" class="btn btn-sm btn-brumby-3">
                View Invoice
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

export default {
  name: "App",
  data() {
    return {
      invoiceHistory: false
    }
  },
  created() {
    this.setup();
  },
  methods: {
    setup() {
      jQuery.post(window.wc_add_to_cart_params.ajax_url, {
        action: 'ajax_invoice_history',
      }, (data, status) => {

        console.log(JSON.parse(data));

        if(!JSON.parse(data)) {
          this.invoiceHistory = {};
          return;
        }

        this.invoiceHistory = JSON.parse(data).sort((a, b) => {
          if ( a.omnis_Invoice < b.omnis_Invoice ){
            return 1;
          }
          if ( a.omnis_Invoice > b.omnis_Invoice ){
            return -1;
          }
          return 0;
        });

        console.log(JSON.parse(data));
      })
    }
  }
}
</script>

<style scoped>

</style>
