var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.item
    ? _c(
        "article",
        {
          staticClass: "ais-Hits-item-grid",
          attrs: { itemtype: "http://schema.org/Article" },
        },
        [
          _vm.item.images.thumbnail
            ? _c("div", { staticClass: "ais-hits--thumbnail" }, [
                _c(
                  "a",
                  {
                    staticClass: "ais-hits--thumbnail-link",
                    attrs: {
                      href: _vm.item.permalink.replace(
                        "brumby.test",
                        "brumby-staging.dev.fweb.com.au"
                      ),
                      title: _vm.item.post_title,
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: this.thumbnail,
                        alt: _vm.item.post_title,
                        title: _vm.item.post_title,
                        itemprop: "image",
                      },
                    }),
                  ]
                ),
              ])
            : _c("div", { staticClass: "ais-hits--thumbnail" }, [
                _c(
                  "a",
                  {
                    staticClass: "ais-hits--thumbnail-link",
                    attrs: {
                      href: _vm.item.permalink.replace(
                        "brumby.test",
                        "brumby-staging.dev.fweb.com.au"
                      ),
                      title: _vm.item.post_title,
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "https://www.brumbysunstate.com.au/app/uploads/woocommerce-placeholder.png",
                        alt: _vm.item.post_title,
                        title: _vm.item.post_title,
                        itemprop: "image",
                      },
                    }),
                  ]
                ),
              ]),
          _vm._v(" "),
          _c("div", { staticClass: "ais-hits--content" }, [
            _c("p", { attrs: { itemprop: "name headline" } }, [
              _c("a", {
                staticClass: "ais-hits--title-link",
                attrs: {
                  href: _vm.item.permalink.replace(
                    "brumby.test",
                    "brumby-staging.dev.fweb.com.au"
                  ),
                  title: _vm.item.post_title,
                  itemprop: "url",
                },
                domProps: {
                  innerHTML: _vm._s(_vm.item._highlightResult.post_title.value),
                },
              }),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "secondary" }, [
              _vm._v("\n      " + _vm._s(_vm.item.author) + "\n    "),
            ]),
          ]),
          _vm._v(" "),
          _vm.isLoggedIn
            ? _c("div", { staticClass: "price-wrapper" }, [
                _c("p", { staticClass: "price" }, [
                  _vm._v("\n      $" + _vm._s(_vm.price(_vm.item)) + "\n    "),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "barcode" }, [
            _c("p", [_vm._v(_vm._s(_vm.item.sku))]),
          ]),
          _vm._v(" "),
          _vm.isLoggedIn
            ? _c("div", { staticClass: "print-only" }, [
                _c("div", { staticClass: "print-info-wrapper" }, [
                  _vm._v("\n      RRP $" + _vm._s(_vm.item.price) + "\n\n    "),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("add-to-cart", {
            attrs: {
              item: _vm.item,
              "show-details": true,
              "post-id": _vm.item.post_id,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }