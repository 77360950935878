"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const component_1 = require("../packages/component-framework/component");
const bootstrap_1 = require("bootstrap");
class NavigationSide extends component_1.Component {
    constructor(element) {
        super(element);
        this.headerText = [];
        this.headerLabel = document.getElementById('navigation-side-label');
        this.headerBackAnchor = document.getElementById('navigation-side-back-element');
        this.registerEventListeners();
    }
    registerEventListeners() {
        var _a;
        /** Event handler to override default menu click event, when clicking on a main menu nav item in desktop **/
        (_a = document.getElementById('primary_nav')) === null || _a === void 0 ? void 0 : _a.querySelectorAll('li.menu-item-has-children a').forEach((el) => {
            el.addEventListener('click', (e) => {
                e.preventDefault();
                // Show the offcanvas and then simulate a click on the corresponding ancor to push the nav to the right item
                bootstrap_1.Offcanvas.getOrCreateInstance(this.element).show();
                // @ts-ignore
                this.element.querySelectorAll(`[data-id="${el.dataset.id}"]`).forEach((element) => {
                    const anchorElement = element;
                    anchorElement.click();
                });
            });
        });
        /** Event handler to go forwards in teh navigation **/
        this.element.querySelectorAll('li.menu-item-has-children > a').forEach((el) => {
            el.addEventListener('click', (e) => {
                e.preventDefault();
                const element = e.target;
                if (!e.currentTarget) {
                    return;
                }
                /** @ts-ignore **/
                this.goForward(element, e.currentTarget.parentNode);
            });
        });
        /** Event handler to go backwards in the navigation **/
        this.element.querySelectorAll('h3 a').forEach((el) => {
            el.addEventListener('click', (e) => {
                e.preventDefault();
                this.goBack();
            });
        });
        /** Reset the navigation when the modal is closed for any reason **/
        this.element.addEventListener('hide.bs.offcanvas', () => {
            this.reset();
        });
    }
    goBack() {
        if (this.headerText.length < 1 || !this.headerLabel) {
            return;
        }
        const menuItem = this.headerText.pop();
        if (!menuItem) {
            return;
        }
        this.headerLabel.innerText = menuItem === null || menuItem === void 0 ? void 0 : menuItem.label;
        menuItem.menuElement.classList.remove('active');
        if (this.headerText.length < 1) {
            this.hideBackButton();
        }
    }
    translateAllMenuItems() {
        if (this.headerText.length < 1) {
            return;
        }
        this.headerText.forEach((item) => {
            item.menuElement.classList.add('translated');
        });
    }
    goForward(anchor, nextElementSibling) {
        var _a;
        if (!this.headerLabel) {
            return;
        }
        this.translateAllMenuItems();
        this.headerText.push({
            label: (_a = this.headerLabel) === null || _a === void 0 ? void 0 : _a.innerText,
            menuElement: nextElementSibling
        });
        this.headerLabel.innerText = anchor.innerText;
        nextElementSibling.classList.add('active');
        this.showBackButton();
    }
    showBackButton() {
        if (!this.headerBackAnchor) {
            return;
        }
        this.headerBackAnchor.classList.add('d-flex');
        this.headerBackAnchor.classList.remove('d-none');
    }
    hideBackButton() {
        if (!this.headerBackAnchor) {
            return;
        }
        this.headerBackAnchor.classList.remove('d-flex');
        this.headerBackAnchor.classList.add('d-none');
    }
    reset() {
        const length = this.headerText.length;
        for (let i = 0; i < length; i++) {
            this.goBack();
        }
    }
}
exports.default = NavigationSide;
NavigationSide.selector = '.offcanvas-navigation';
