var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "algolia-datepicker-container" },
    [
      _vm._t(
        "default",
        function () {
          return [
            _c(
              "span",
              { staticClass: "algolia-datepicker" },
              [
                _c("span", { staticClass: "algolia-datepicker-overlay" }, [
                  _c("i", { staticClass: "fal fa-calendar" }),
                  _vm._v(" "),
                  !_vm.minInputValue && !_vm.minInput
                    ? _c("p", [_vm._v("Start Date")])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("datepicker", {
                  attrs: { value: _vm.minInputValue },
                  on: { selected: _vm.minInputSelected },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "algolia-datepicker" },
              [
                _c("span", { staticClass: "algolia-datepicker-overlay" }, [
                  _c("i", { staticClass: "fal fa-calendar" }),
                  _vm._v(" "),
                  !_vm.maxInputValue && !_vm.maxInput
                    ? _c("p", [_vm._v("End Date")])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("datepicker", {
                  attrs: { value: _vm.maxInputValue },
                  on: { selected: _vm.maxInputSelected },
                }),
              ],
              1
            ),
          ]
        },
        {
          currentRefinement: _vm.values,
          refine: _vm.refine,
          canRefine: _vm.canRefine,
        }
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }